import React from 'react'
import { useDebouncedCallback } from 'use-debounce'
import Container from '~/components/Container'
import { cn } from '~/utils/cn'

export const NAV_BAR_HEIGHT = 76

export type NavbarGridProps = {
  className?: string
  children: React.ReactNode
}

export const NavbarGrid = ({ className = '', children }: NavbarGridProps) => {
  return (
    <Container fluid className="h-[var(--nav-height)] py-3 max-md:px-4">
      <nav
        className={cn(
          'grid h-full w-full grid-cols-[1fr_1fr] md:grid-cols-[1fr_auto_1fr]',
          className
        )}
      >
        {children}
      </nav>
    </Container>
  )
}

export type NavbarGridItemProps = {
  className?: string
  align: 'start' | 'center' | 'end'
  children?: React.ReactNode
}

NavbarGrid.NavbarGridItem = ({
  className = '',
  align,
  children = null
}: NavbarGridItemProps) => {
  return (
    <div
      className={cn('flex items-center md:px-2', className, {
        'justify-start': align === 'start',
        'justify-center max-md:hidden': align === 'center',
        'justify-end': align === 'end'
      })}
    >
      {children}
    </div>
  )
}

export function useNavExtended() {
  const subMenuRef = React.useRef<HTMLDivElement>(null)
  const [currentActiveIndex, setCurrentActiveIndex] = React.useState<
    number | null
  >(null)

  const hideActiveIndex = () => {
    setCurrentActiveIndex(null)
  }

  const startDeactivate = useDebouncedCallback(() => {
    // Do not hide the active index if we have a focused element inside the submenu
    if (
      subMenuRef.current &&
      document.activeElement &&
      subMenuRef.current.contains(document.activeElement)
    ) {
      return
    }

    hideActiveIndex()
  }, 150)

  const handleMouseEnter = (index: number) => {
    return () => {
      setCurrentActiveIndex(index)
      startDeactivate.cancel()
    }
  }

  return {
    hideExtendedMenu: startDeactivate,
    currentActiveIndex,
    linkProps: (index: number) => {
      return {
        onMouseLeave: startDeactivate,
        onFocus: handleMouseEnter(index),
        onBlur: startDeactivate,
        onMouseEnter: handleMouseEnter(index)
      } satisfies React.ComponentPropsWithoutRef<'button'>
    }
  }
}
